'use client';

import React, { useEffect, useState } from 'react';

import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

import Logo from '@/public/images/sarah-sweeps-logo-01.png';

import MenuItems from '../MenuItems';
import MobileMenu from './mobile-menu';

export default function Header() {
  const pathname = usePathname();
  const isActive = (path: string) =>
    pathname === path ? 'text-sky-500' : 'text-gray-800';

  const [isLogged, setIsLogged] = useState(false);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    const storedAuth = localStorage.getItem('admin');
    if (storedAuth) {
      setIsLogged(true);
    } else {
      setIsLogged(false);
    }
  }, []);

  const logOut = () => {
    if (typeof window !== 'undefined') {
      localStorage.removeItem('admin');
      setIsLogged(false);
    }
  };

  return (
    <header className="absolute w-full z-30 bg-white shadow-md">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="flex items-center justify-between">
          {/* Site branding */}
          <div className="shrink-0 mr-4 p-5">
            {/* Logo */}
            <Link href="/" className="block" aria-label="Cruip">
              <Image
                src={Logo}
                alt="Sarah Sweeps Logo"
                width={250}
                height={85}
                quality={100}
                priority
                sizes="(max-width: 640px) 150px, (max-width: 768px) 200px, (max-width: 1024px) 250px, 300px"
                className="w-[150px] sm:w-[200px] md:w-[250px] lg:w-[300px]"
              />
            </Link>
          </div>

          {/* Desktop navigation */}
          <nav className="hidden md:flex md:grow">
            {/* Desktop sign in links */}
            <MenuItems
              ulClassName="flex grow justify-end flex-wrap items-center"
              isLogged={isLogged}
              logOut={logOut}
              isActive={isActive}
              handleLinkClick={() => {}}
            />
          </nav>

          <MobileMenu logOut={logOut} isActive={isActive} isLogged={isLogged} />
        </div>
      </div>
    </header>
  );
}
