import(/* webpackMode: "eager" */ "/app/app/css/style.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/client-side/header.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\",\"display\":\"swap\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Architects_Daughter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-architects-daughter\",\"weight\":\"400\",\"display\":\"swap\"}],\"variableName\":\"architects_daughter\"}");
