import Link from 'next/link';

interface MenuItemsProps {
  ulClassName: string;
  isLogged: boolean;
  logOut: () => void;
  isActive: (path: string) => string;
  handleLinkClick: () => void;
}

export default function MenuItems({
  ulClassName,
  isLogged,
  logOut,
  isActive,
  handleLinkClick,
}: MenuItemsProps) {
  return (
    <ul className={ulClassName}>
      <li>
        <Link
          href="/"
          className={`font-medium hover:text-sky-500 px-4 py-3 flex items-center transition duration-150 ease-in-out ${isActive('/')}`}
          onClick={handleLinkClick}
        >
          Home
        </Link>
      </li>
      <li>
        <Link
          href="/about"
          className={`font-medium hover:text-sky-500 px-4 py-3 flex items-center transition duration-150 ease-in-out ${isActive('/about')}`}
          onClick={handleLinkClick}
        >
          About
        </Link>
      </li>
      <li>
        <Link
          href="/contact"
          className={`font-medium hover:text-sky-500 px-4 py-3 flex items-center transition duration-150 ease-in-out ${isActive('/contact')}`}
          onClick={handleLinkClick}
        >
          Contact
        </Link>
      </li>
      {isLogged && (
        <li>
          <Link
            href="/admin"
            className={`font-medium hover:text-sky-500 px-4 py-3 flex items-center transition duration-150 ease-in-out ${isActive('/admin')}`}
            onClick={handleLinkClick}
          >
            Admin
          </Link>
        </li>
      )}
      {isLogged && (
        <li>
          <Link
            href="/"
            onClick={logOut}
            className="font-medium hover:text-sky-500 px-4 py-3 flex items-center transition duration-150 ease-in-out"
          >
            Logout
          </Link>
        </li>
      )}
    </ul>
  );
}
